import React from 'react'
import { Link } from 'react-router-dom'

const PrivarcyPolicyContent = () => {
  return (
    <div>
                <h1 className="w-full text-lg font-bold text-black underline underline-offset-4 text-center">
          Privacy Policy
        </h1>
        <div className="w-full space-y-1 ">
          <p className="  ">Privacy Policy Last updated: Aug 09, 2024</p>
          <p className="text-sm  ">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects you.
          </p>
          <p className="text-sm  ">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.{" "}
          </p>
        </div>
        <h2 className="w-full text-base text-text_primaryDarkBlue font-bold my-4">
          Interpretation and Definitions
        </h2>
        <div>
          <h3 className="w-full text-sm text-text_primaryDarkBlue font-semibold mt-2 mb-1">
            Interpretation
          </h3>
          <p className="text-sm  ">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
        </div>
        <div>
          <h3 className="w-full text-sm text-text_primaryDarkBlue font-semibold mt-2 mb-1">
            {" "}
            Definitions
          </h3>
          <p className="  ">For the purposes of this Privacy Policy:</p>

          <div className="my-2 space-y-1">
            <p>
              <span>Account</span> means a unique account created for You to
              access our Service or parts of our Service.
            </p>
            <p>
              <span className="font-bold text-black">Application</span> refers
              to P2P Trading, the software program provided by the Company.
              Company (referred to as either "the Company", "We", "Us" or "Our"
              in this Agreement) refers to P2P Trading.
            </p>
            <p>
              <span className="font-bold text-black">Cookies</span> are small
              files that are placed on Your computer, mobile device or any other
              device by a website, containing the details of Your browsing
              history on that website among its many uses.
            </p>
            <p>
              <span className="font-bold text-black">Country</span> refers to:
              Uttar Pradesh, India
            </p>
            <p>
              <span className="font-bold text-black">Device</span> means any
              device that can access the Service such as a computer, a cellphone
              or a digital tablet.
            </p>
            <p>
              <span className="font-bold text-black">Personal Data</span> is any
              information that relates to an identified or identifiable
              individual.
            </p>
            <p>
              <span className="font-bold text-black">Service</span> refers to
              the Application or the Website or both.
            </p>
            <p>
              <span className="font-bold text-black">Service Provider</span>{" "}
              means any natural or legal person who processes the data on behalf
              of the Company. It refers to third-party companies or individuals
              employed by the Company to facilitate the Service, to provide the
              Service on behalf of the Company, to perform services related to
              the Service or to assist the Company in analyzing how the Service
              is used.
            </p>
            <p>
              <span className="font-bold text-black">Usage Data</span> refers to
              data collected automatically, either generated by the use of the
              Service or from the Service infrastructure itself (for example,
              the duration of a page visit).
            </p>
            <p>
              <span className="font-bold text-black">Website</span> refers to
              P2P Trading, accessible from{" "}
              <span className="space-x-2">
                <Link
                  to="https://www.p2ptrading
            .com"
                  target="_blank"
                  className="text-text_primaryDarkBlue underline underline-offset-2 cursor-pointer"
                >
                  https://www.p2ptrading .com
                </Link>
                <Link
                  to="https://www.p2ptrading .com"
                  target="_blank"
                  className="text-text_primaryDarkBlue underline underline-offset-2 cursor-pointer"
                >
                  https://www.p2ptrading .com
                </Link>
              </span>
            </p>
            <p>
              {" "}
              <span className="font-bold text-black">You</span> means the
              individual accessing or using the Service, or the company, or
              other legal entity on behalf of which such individual is accessing
              or using the Service, as applicable.
            </p>
          </div>
        </div>

        <div>
          <h2 className="w-full text-base text-text_primaryDarkBlue font-bold my-4">
            Collecting and Using Your Personal Data
          </h2>

          <h3 className="w-full text-sm text-text_primaryDarkBlue font-semibold mt-2 mb-1">
            Types of Data Collected
          </h3>
          <h3 className="w-full text-[13px] text-text_primaryDarkBlue font-medium mt-2 mb-1 italic">
            Personal Data :
          </h3>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <div className="flex flex-col ml-20 mt-4">
            {" "}
            <span>Email address</span>
            <span>First name and last name</span>
            <span>Phone number</span>
            <span>Address, State, Province, ZIP/Postal code, City</span>
            <span>Plant details</span>
            <span>Usage Data</span>
          </div>

          <h3 className="w-full text-[13px] text-text_primaryDarkBlue font-medium mt-2 mb-1 italic">
            Usage Data :
          </h3>
          <div className="space-y-2">
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data
            </p>
            <p>
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>
            <p>
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
          </div>
          <div>
            <h3 className="w-full text-[13px] text-text_primaryDarkBlue font-medium mt-2 mb-1 italic">
              Information Collected while Using the Application :
            </h3>
            <p>
              While using Our Application, in order to provide features of Our
              Application, We may collect, with Your prior permission:
            </p>
            <h3 className="flex items-center space-x-6 font-medium my-4">
              <div className="w-[6px] h-[6px] rounded-full bg-black"></div>
              <div>Information regarding your location</div>
            </h3>
            <p>
              We use this information to provide features of Our Service, to
              improve and customize Our Service. The information may be uploaded
              to the Company's servers and/or a Service Provider's server or it
              may be simply stored on Your device.
            </p>
            <p>
              You can enable or disable access to this information at any time,
              through Your Device settings.
            </p>
          </div>
        </div>
        <div>
          <h3 className="w-full text-[13px] text-text_primaryDarkBlue font-medium mt-2 mb-1 italic">
            Tracking Technologies and Cookies :
          </h3>

          <p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </p>
          <div className="w-full">
            <div className="flex items-center space-x-2">
              <div className="w-[6px] h-[6px] rounded-full bg-black"></div>
              <span className="font-bold whitespace-nowrap">
                Cookies or Browser Cookies.
              </span>
            </div>
            <div>
              <div>
                A cookie is a small file placed on Your Device. You can instruct
                Your browser to refuse all Cookies or to indicate when a Cookie
                is being sent. However, if You do not accept Cookies, You may
                not be able to use some parts of our Service. Unless you have
                adjusted Your browser setting so that it will refuse Cookies,
                our Service may use Cookies.
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-center space-x-2">
              <div className="w-[6px] h-[6px] rounded-full bg-black"></div>
              <span className="font-bold whitespace-nowrap">Web Beacons.</span>
            </div>
            <div>
              <div>
                Certain sections of our Service and our emails may contain small
                electronic files known as web beacons (also referred to as clear
                gifs, pixel tags, and single-pixel gifs) that permit the
                Company, for example, to count users who have visited those
                pages or opened an email and for other related website
                statistics (for example, recording the popularity of a certain
                section and verifying system and server integrity).
              </div>
            </div>
          </div>

          <p className="my-2">
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. You can learn more about cookies on TermsFeed website
            article.
          </p>
          <p className="my-2">
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <div className="ml-28">
            <h3 className="font-semibold text-sm ">
              Necessary / Essential Cookies
            </h3>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </p>

            <h3 className="font-semibold text-sm ">
              Cookies Policy / Notice Acceptance Cookies
            </h3>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </p>
            <h3 className="font-semibold text-sm ">Functionality Cookies</h3>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website
            </p>
          </div>
          <p className="mt-2">
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
        </div>

        <div>
          <h3 className="w-full text-sm text-text_primaryDarkBlue font-semibold mt-2 mb-1">
            Use of Your Personal Data
          </h3>
          <p>The Company may use Personal Data for the following purposes:</p>
          <div className="ml-28 my-4">
            <p>
              <span className="font-semibold">
                To provide and maintain our Service,
              </span>{" "}
              including to monitor the usage of our Service.
            </p>
            <p>
              <span className="font-semibold">To manage Your Account:</span> to
              manage Your registration as a user of the Service. The Personal
              Data You provide can give You access to different functionalities
              of the Service that are available to You as a registered user.
            </p>
            <p>
              <span className="font-semibold">
                For the performance of a contract: the development, compliance
                and undertaking of the purchase contract for the products, items
                or services You have purchased or of any other contract with Us
                through the Service.
              </span>
            </p>
            <p>
              <span className="font-semibold">To contact You: </span>To contact
              You by email, telephone calls, SMS, or other equivalent forms of
              electronic communication, such as a mobile application's push
              notifications regarding updates or informative communications
              related to the functionalities, products or contracted services,
              including the security updates, when necessary or reasonable for
              their implementation.
            </p>
            <p>
              <span className="font-semibold">To provide You</span> with news,
              special offers and general information about other goods, services
              and events which we offer that are similar to those that you have
              already purchased or enquired about unless You have opted not to
              receive such information.{" "}
            </p>
            <p>
              <span className="font-semibold">To manage Your requests:</span> To
              attend and manage Your requests to Us. For business transfers: We
              may use Your information to evaluate or conduct a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of Our assets, whether as a going
              concern or as part of bankruptcy, liquidation, or similar
              proceeding, in which Personal Data held by Us about our Service
              users is among the assets transferred.
            </p>
            <p>
              {" "}
              <span className="font-semibold">For other purposes: </span>We may
              use Your information for other purposes, such as data analysis,
              identifying usage trends, determining the effectiveness of our
              promotional campaigns and to evaluate and improve our Service,
              products, services, marketing and your experience.
            </p>
          </div>
          <p>
            We may share Your personal information in the following situations:
          </p>
          <div className="w-full">
            <div>
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-black rounded-full"></div>
                <div className="font-semibold">With Service Providers: </div>
              </div>
              <p className="ml-4">
                We may share Your personal information with Service Providers to
                monitor and analyze the use of our Service, to contact You.
              </p>
            </div>

            <div>
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-black rounded-full"></div>
                <div className="font-semibold"> For business transfers:</div>
              </div>
              <p className="ml-4">
                We may share or transfer Your personal information in connection
                with, or during negotiations of, any merger, sale of Company
                assets, financing, or acquisition of all or a portion of Our
                business to another company.
              </p>
            </div>

            <div>
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-black rounded-full"></div>
                <div className="font-semibold">With Affiliates: </div>
              </div>
              <p className="ml-4">
                We may share Your information with Our affiliates, in which case
                we will require those affiliates to honor this Privacy Policy.
                Affiliates include Our parent company and any other
                subsidiaries, joint venture partners or other companies that We
                control or that are under common control with Us.
              </p>
            </div>

            <div>
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-black rounded-full"></div>
                <div className="font-semibold">With business partners: </div>
              </div>
              <p className="ml-4">
                We may share Your information with Our business partners to
                offer You certain products, services or promotions.
              </p>
            </div>

            <div>
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-black rounded-full"></div>
                <div className="font-semibold"> With other users:</div>
              </div>
              <p className="ml-4">
                When You share personal information or otherwise interact in the
                public areas with other users, such information may be viewed by
                all users and may be publicly distributed outside.
              </p>
            </div>

            <div>
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-black rounded-full"></div>
                <div className="font-semibold">With Your consent: </div>
              </div>
              <p className="ml-4">
                We may disclose Your personal information for any other purpose
                with Your consent.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h3 className="w-full text-sm text-text_primaryDarkBlue font-semibold mt-2 mb-1">
            Retention of Your Personal Data
          </h3>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p className="my-2">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
        </div>

        <div>
          <h3 className="w-full text-sm text-text_primaryDarkBlue font-semibold mt-2 mb-1">
            Transfer of Your Personal Data
          </h3>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>

          <p className="my-1">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p className="my-1">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
        </div>
        <div>
          <h3 className="w-full text-sm text-text_primaryDarkBlue font-semibold mt-2 mb-1">
            Delete Your Personal Data
          </h3>

          <p>
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>
          <p className="my-2">
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>

          <p className="my-2">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>
          <p className="my-2">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
        </div>

        <div>
          <h3 className="w-full text-sm text-text_primaryDarkBlue font-semibold mt-2 mb-1">
            Regulatory Approval and Participant Responsibility
          </h3>

          <div className="">
            <span>
              Our P2P solar energy trading platform operates under rules
              approved by the Utter Pradesh Electricity Regulatory Commission
              (UPERC)
            </span>
            <Link
              to="https://www.uperc.org/App_File/P2P-Guidelines_UPERC-pdf416202393822PM.pdf."
              target="_blank"
              className="text-text_primaryDarkBlue cursor-pointer underline underline-offset-4"
            >
              {" "}
              https://www.uperc.org/App_File/P2P-Guidelines_UPERC-pdf416202393822PM.pdf.
            </Link>
            <span>
              However, participants must independently assess all commercial
              aspects of their transactions, including fees and charges. UPERC’s
              approval does not imply endorsement of specific commercial terms
              or guarantee transaction outcomes. All interactions and agreements
              between participants are private, and participants are responsible
              for evaluating the risks and benefits involved in trading on the
              platform.
            </span>
          </div>
        </div>

        <div>
          <h2 className="w-full text-sm text-text_primaryDarkBlue font-semibold mt-2 mb-1">
            Disclosure of Your Personal Data
          </h2>

          <h3 className="mt-2 mb-1 font-medium text-[12px] text-text_primaryDarkBlue italic">
            Business Transactions
          </h3>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>

          <h3 className="mt-2 mb-1 font-medium text-[12px] text-text_primaryDarkBlue italic">
            Law enforcement
          </h3>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>

          <h3 className="mt-2 mb-1 font-medium text-[12px] text-text_primaryDarkBlue italic">
            Other legal requirements
          </h3>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <div className="w-full my-2 ml-4">
            <div className="flex items-center space-x-1">
              <div className="w-[5px] h-[5px] bg-black rounded-full"></div>
              <div>Comply with a legal obligation</div>
            </div>

            <div className="flex items-center space-x-1">
              <div className="w-[5px] h-[5px] bg-black rounded-full"></div>
              <div>
                Protect and defend the rights or property of the Company
              </div>
            </div>

            <div className="flex items-center space-x-1">
              <div className="w-[5px] h-[5px] bg-black rounded-full"></div>
              <div>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </div>
            </div>

            <div className="flex items-center space-x-1">
              <div className="w-[5px] h-[5px] bg-black rounded-full"></div>
              <div>
                Protect the personal safety of Users of the Service or the
                public
              </div>
            </div>

            <div className="flex items-center space-x-1">
              <div className="w-[5px] h-[5px] bg-black rounded-full"></div>
              <div>Protect against legal liability</div>
            </div>
          </div>
        </div>

        <div>
          <h2 className="w-full text-sm text-text_primaryDarkBlue font-semibold mt-2 mb-1">
            Security of Your Personal Data
          </h2>

          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
        </div>

        <div>
          <h2 className="w-full text-base text-text_primaryDarkBlue font-bold my-4">
            Children's Privacy
          </h2>
          <p className="my-1">
            Our Service does not address anyone under the age of 18. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 18. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p className="my-1">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
        </div>

        <div>
          <h2 className="w-full text-base text-text_primaryDarkBlue font-bold my-4">
            Changes to this Privacy Policy
          </h2>
          <p className="my-1">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="my-1">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p className="my-1">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>

        <div>
          <h2 className="w-full text-base text-text_primaryDarkBlue font-bold my-4">
            Contact Us
          </h2>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <div className="ml-4">
            <div className="flex items-center space-x-2 ">
              <div className="w-[5px] h-[5px] bg-black rounded-full"></div>
              <div>
                <span>By email:</span>{" "}
                <span className="text-text_primaryDarkBlue underline underline-offset-4">
                  support@p2p.com
                </span>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <div className="w-[5px] h-[5px] bg-black rounded-full"></div>
              <div>
                <span>Phone Number:</span>{" "}
                <span className="text-text_primaryDarkBlue underline underline-offset-4">
                  +91 ##############
                </span>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <div className="w-[5px] h-[5px] bg-black rounded-full"></div>
              <div>
                <span>Website:</span>{" "}
                <Link
                  className="text-text_primaryDarkBlue underline underline-offset-4"
                  to={"www. p2ptrading.com"}
                  target="_blank"
                >www. p2ptrading.com</Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default PrivarcyPolicyContent